/**
 * Copyright 2023 SweetCode. All rights reserved.
 *
 * Load TikTok Ads functions
 * */

(function (wpm, $, undefined) {

	wpm.loadTikTokPixel = () => {

		try {
			wpmDataLayer.pixels.tiktok.loaded = true

			// @formatter:off
			!function (w, d, t) {
				w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

				ttq.load(wpmDataLayer.pixels.tiktok.pixel_id);
				ttq.page();
			}(window, document, 'ttq');
			// @formatter:on

			if (
				wpmDataLayer?.pixels?.tiktok?.advanced_matching
				&& (
					wpmDataLayer?.user?.email?.sha256
					|| wpmDataLayer?.user?.phone?.sha256?.e164
				)
			) {
				let identity = {}

				// https://ads.tiktok.com/marketing_api/docs?id=1739585700402178
				if (wpmDataLayer?.user?.email?.sha256) identity.sha256_email = wpmDataLayer.user.email.sha256
				if (wpmDataLayer?.user?.phone?.sha256?.e164) identity.sha256_phone_number = wpmDataLayer.user.phone.sha256.e164

				ttq.identify(identity)
			}

		} catch (e) {
			console.error(e)
		}
	}

	wpm.getTikTokOrderItemIds = () => {

		let orderItems = []

		Object.values(wpmDataLayer.order.items).forEach((item) => {

			let orderItem

			orderItem = {
				content_type: "product",
				quantity    : item.quantity,
				price       : item.price,
			}

			if (wpmDataLayer?.general?.variationsOutput && 0 !== item.variation_id) {

				orderItem.content_id   = String(wpmDataLayer.products[item.variation_id].dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type])
				orderItem.content_name = wpmDataLayer.products[item.variation_id].name
				orderItems.push(orderItem)
			} else {

				orderItem.content_id   = String(wpmDataLayer.products[item.id].dyn_r_ids[wpmDataLayer.pixels.tiktok.dynamic_remarketing.id_type])
				orderItem.content_name = wpmDataLayer.products[item.id].name
				orderItems.push(orderItem)
			}
		})

		return orderItems
	}

	wpm.getTikTokUserDataFromBrowser = () => {

		let
			data = {
				user: {},
				page: {},

			}

		if (wpm.getCookie("_ttp") && wpm.isValidTtp(wpm.getCookie("_ttp"))) {
			data.user.ttp = wpm.getCookie("_ttp")
		}

		if (wpm.getCookie("_ttclid") && wpm.isValidTtclid(wpm.getCookie("_ttclid"))) {
			data.ad = {
				"callback": wpm.getCookie("_ttclid"),
			}
		}

		if (window.location.href) {

			// Remove the parameters from the URL and save it to data.page.url
			data.page.url = window.location.href.split("?")[0]
		}

		if (wpm.getCookie("wpmReferrer")) {
			data.page.referrer = wpm.getCookie("wpmReferrer")

			// If http is missing, add it
			if (!data.page.referrer.startsWith("http")) data.page.referrer = "https://" + data.page.referrer
		}

		if (wpmDataLayer?.pixels?.tiktok?.advanced_matching) {
			if (wpmDataLayer?.user?.id?.sha256) data.user.external_id = wpmDataLayer.user.id.sha256
			if (wpmDataLayer?.user?.email?.sha256) data.user.sha256_email = wpmDataLayer.user.email.sha256
			if (wpmDataLayer?.user?.phone?.sha256?.e164) data.user.sha256_phone_number = wpmDataLayer.user.phone.sha256.e164
			if (navigator.userAgent) data.user_agent = navigator.userAgent
		}

		// If data.user is empty, remove it
		if (Object.keys(data.user).length === 0) delete data.user

		return data
	}

	wpm.isValidTtp = ttp => {

		let re = new RegExp(/^[\da-zA-Z-]{20,50}$/)

		return re.test(ttp)
	}

	wpm.isValidTtclid = ttclid => {

		let re = new RegExp(/^[\da-zA-z-]{5,600}$/)

		return re.test(ttclid)
	}

}(window.wpm = window.wpm || {}, jQuery));
